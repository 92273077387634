













































































































import { AxiosError } from 'axios';
import {
  Component, Ref, Vue,
} from 'vue-property-decorator';
import Services from '@/assets/services/Services';
import ButtonType from '@/assets/buttonTypes';
import RoundedButtonOutlined from '@/components/partials/RoundedButtonOutlined.vue';
import ContactAddress, { AddressData } from '@/components/contact/ContactAddress.vue';
import LinkWithArrow from '@/components/partials/LinkWithArrow.vue';
import ImageHeader from '@/components/partials/ImageHeader.vue';

interface Message {
  name: string;
  email: string;
  text: string;
}

// eslint-disable-next-line no-shadow
enum ErrorCode {
  NAME_EMPTY='NAME_EMPTY',
  EMAIL_EMPTY='EMAIL_EMPTY',
  EMAIL_INVALID='EMAIL_INVALID',
  MESSAGE_EMPTY='MESSAGE_EMPTY',
}

@Component({
  components: {
    RoundedButtonOutlined, ContactAddress, LinkWithArrow, ImageHeader,
  },
  metaInfo: {
    title: 'Kontakt │ innovaMo',
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content:
          'Sie haben Fragen oder möchten Ihre digitale Mobilitätslösung auf innovaMo platzieren? Kontaktieren Sie uns jetzt >> info@innovamo.de >> 0441 559 770 13',
      },
      {
        vmid: 'preview-image',
        property: 'og:image',
        content: 'https://www.innovamo.de/img/seo/handshake.png',
      },
      {
        vmid: 'preview-image-twitter',
        property: 'twitter:image',
        content: 'https://www.innovamo.de/img/seo/handshake.png',
      },
    ],
  },
})
export default class Contact extends Vue {
  private ButtonType = ButtonType;

  private addressData: AddressData = {
    name: 'worldiety GmbH',
    address: 'Technologie- und Gründerzentrum OL',
    street: 'Marie-Curie-Straße 1',
    town: '26129 Oldenburg',
  }

  private showSuccess: boolean = false;
  private showErrors: boolean = false;

  @Ref('inputName')
  private inputName!: HTMLInputElement;

  @Ref('inputMessage')
  private inputMessage!: HTMLInputElement;

  @Ref('inputEmail')
  private inputEmail!: HTMLInputElement;

  private errorCodeToMessageMap: Map<ErrorCode, string> = new Map<ErrorCode, string>([
    [ErrorCode.NAME_EMPTY, 'Bitte geben Sie Ihren Namen an'],
    [ErrorCode.MESSAGE_EMPTY, 'Bitte geben Sie eine Nachricht ein'],
    [ErrorCode.EMAIL_INVALID, 'Bitte geben Sie eine gültige Email Adresse an'],
  ])

  private form: Message = {
    email: '',
    name: '',
    text: '',
  };

  private clearForm() {
    this.form = {
      email: '',
      name: '',
      text: '',
    };
  }

  private setErrorMessages(errorCodes: string[]) {
    if (errorCodes.includes(ErrorCode.NAME_EMPTY)) {
      this.inputName.setCustomValidity(this.errorCodeToMessageMap.get(ErrorCode.NAME_EMPTY) as string);
    }

    if (errorCodes.includes(ErrorCode.MESSAGE_EMPTY)) {
      this.inputMessage.setCustomValidity(this.errorCodeToMessageMap.get(ErrorCode.MESSAGE_EMPTY) as string);
    }

    if (errorCodes.includes(ErrorCode.EMAIL_EMPTY) || errorCodes.includes(ErrorCode.EMAIL_INVALID)) {
      this.inputEmail.setCustomValidity(this.errorCodeToMessageMap.get(ErrorCode.EMAIL_INVALID) as string);
    }
  }

  private resetErrorMessages() {
    this.inputName.setCustomValidity('');
    this.inputMessage.setCustomValidity('');
    this.inputEmail.setCustomValidity('');
  }

  private async sendForm() {
    this.showErrors = true;
    try {
      await Services.mails.sendContactRequest({
        name: this.form.name,
        email: this.form.email,
        message: this.form.text,
      });
      this.clearForm();
      this.showSuccess = true;
      this.showErrors = false;
      this.resetErrorMessages();
      setTimeout(() => this.showSuccess = false, 3000);
    } catch (err) {
      const error = err as AxiosError;

      if (error.response) {
        this.setErrorMessages(error.response.data.errors);
      }
    }
  }
}
