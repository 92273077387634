


















import { Vue, Component, Prop } from 'vue-property-decorator';

export interface AddressData {
  name: string,
  address: string,
  street: string,
  town: string,
}

@Component({})
export default class ContactAddress extends Vue {

    @Prop({ required: true })
    public readonly data!: AddressData;
}
